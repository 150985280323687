import * as React from "react"

const HostingStorageKey = {
  Hosting: `hideHostingNotification`,
  CustomDomain: `showCustomDomainNotification`,
  GastbyCloudPlugin: `showGatsbyCloudPluginNotification`,
}

export function useGatsbyHostingNotifications(siteId: string) {
  const isBrowser = typeof window !== `undefined`

  const hostingNotificationKey = `${HostingStorageKey.Hosting}:${siteId}`
  const customDomainNotificationKey = `${HostingStorageKey.CustomDomain}:${siteId}`
  const gatsbyCloudPluginNotificationKey = `${HostingStorageKey.GastbyCloudPlugin}:${siteId}`

  const hostingNotificationVisibleInitValue =
    isBrowser && window.sessionStorage.getItem(hostingNotificationKey)
      ? false
      : true

  const customDomainNotificationVisibleInitValue =
    isBrowser && window.sessionStorage.getItem(customDomainNotificationKey)
      ? false
      : true

  // also need to know if the plugin is installed
  const gatsbyCloudPluginNotificationVisibleInitValue =
    isBrowser && window.sessionStorage.getItem(gatsbyCloudPluginNotificationKey)
      ? false
      : true

  const [
    hostingNotificationVisible,
    setHostingNotificationVisible,
  ] = React.useState(hostingNotificationVisibleInitValue)

  const [
    customDomainNotificationVisible,
    setCustomDomainNotificationVisible,
  ] = React.useState(customDomainNotificationVisibleInitValue)

  const [
    gatsbyCloudPluginNotificationVisible,
    setGatsbyCloudPluginNotificationVisible,
  ] = React.useState(gatsbyCloudPluginNotificationVisibleInitValue)

  function hideHostingNotification() {
    setHostingNotificationVisible(false)
    window.sessionStorage.setItem(hostingNotificationKey, `1`)
  }

  function hideCustomDomainNotification() {
    setCustomDomainNotificationVisible(false)
    window.sessionStorage.setItem(customDomainNotificationKey, `1`)
  }

  function hideGatsbyCloudPluginNotification() {
    setGatsbyCloudPluginNotificationVisible(false)
    window.sessionStorage.setItem(gatsbyCloudPluginNotificationKey, `1`)
  }

  return {
    hostingNotificationVisible,
    customDomainNotificationVisible,
    gatsbyCloudPluginNotificationVisible,
    hideHostingNotification,
    hideCustomDomainNotification,
    hideGatsbyCloudPluginNotification,
  }
}
