import * as React from "react"
import { EmptyState } from "gatsby-interface"

function Icon() {
  return (
    <svg
      width="104"
      height="91"
      viewBox="0 0 104 91"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M87.0001 90.4582C97.4447 80.9472 104 67.2391 104 52C104 23.2812 80.7188 0 52 0C23.2812 0 0 23.2812 0 52C0 67.0219 6.36977 80.5561 16.5557 90.0489V28H76.4334L87.0001 38.5667V90.4582Z"
        fill="#F6EDFA"
      />
      <path
        d="M76.0845 28C76.0845 28 76.0845 31.5222 76.0845 35.0444C76.0845 38.5667 79.6067 38.5667 79.6067 38.5667H86.6511"
        stroke="#8954A8"
        strokeWidth="0.644444"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M87.0001 90.5V38.5667L76.4334 28H16.5557V90.5"
        stroke="#8954A8"
        strokeWidth="0.644444"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.5 47C32.5376 47 35 44.5376 35 41.5C35 38.4624 32.5376 36 29.5 36C26.4624 36 24 38.4624 24 41.5C24 44.5376 26.4624 47 29.5 47Z"
        fill="#F1DEFA"
      />
      <path
        d="M29.5 67C32.5376 67 35 64.5376 35 61.5C35 58.4624 32.5376 56 29.5 56C26.4624 56 24 58.4624 24 61.5C24 64.5376 26.4624 67 29.5 67Z"
        fill="#F1DEFA"
      />
      <path
        d="M29.5 86C32.5376 86 35 83.5376 35 80.5C35 77.4624 32.5376 75 29.5 75C26.4624 75 24 77.4624 24 80.5C24 83.5376 26.4624 86 29.5 86Z"
        fill="#F1DEFA"
      />
      <path
        d="M27 41L28.8182 43L32 39.5"
        stroke="#663399"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27 61.5L28.8182 63.5L32 60"
        stroke="#663399"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27 80L28.8182 82L32 78.5"
        stroke="#663399"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export type NoDeploysProps = {
  productionBranch: string
}
export function NoDeploys({ productionBranch }: NoDeploysProps) {
  return (
    <EmptyState
      variant="BORDERED"
      heading={`You don’t have any Deploy Previews`}
      text={
        <span data-testid="deploy-previews-details">
          Deploy Previews will be automatically generated when you open a pull
          request to <strong>{productionBranch}</strong>
        </span>
      }
      graphic={<Icon />}
    />
  )
}
