import * as React from "react"
import {
  cdnVendorNames,
  cdnVendorBasePaths,
  cdnVendorThumbnails,
} from "@modules/cdn/shared/constants"
import { cdnIntegration as cdnIntegrationText } from "@modules/locales/default.json"
import { CdnVendor } from "@modules/graphql/types"
import { interpolateMessage } from "@modules/locales"
import { IntegrationChip } from "@modules/site/shared/components/IntegrationChip"

export type CdnIntegrationChipProps = {
  vendor: CdnVendor
  organizationId: string
  siteId: string
}

export function CdnIntegrationChip({
  vendor,
  organizationId,
  siteId,
}: CdnIntegrationChipProps) {
  const Icon = cdnVendorThumbnails[vendor]

  let href = cdnVendorBasePaths[vendor]

  if (vendor === CdnVendor.CloudCdn) {
    href = `/dashboard/${organizationId}/sites/${siteId}/settings/hosting`
  }

  return (
    <IntegrationChip
      label={interpolateMessage<"cdnVendorName">(
        cdnIntegrationText.labels.siteDeploysTo,
        { cdnVendorName: cdnVendorNames[vendor] }
      )}
      icon={<Icon />}
      href={href}
    />
  )
}
