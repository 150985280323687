import { useSiteDetailsQuery } from "@modules/site/shared/queries.generated"

export default function useSiteDetailsData(
  siteId?: string,
  options?: { shouldSkip?: boolean }
) {
  const {
    loading,
    data,
    error,
    startPolling,
    stopPolling,
  } = useSiteDetailsQuery({
    variables: { id: siteId },
    fetchPolicy: `cache-and-network`,
    skip: !siteId || options?.shouldSkip,
  })

  const siteDetails = data && data.siteDetails

  return [
    siteDetails,
    {
      loading: loading && !(siteDetails && siteDetails.id),
      error,
      startPolling,
      stopPolling,
    },
  ] as const
}
