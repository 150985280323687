// THIS IS A GENERATED FILE
import * as Types from "../../graphql/types"

import gql from "graphql-tag"
import * as ApolloReactCommon from "@apollo/react-common"
import * as ApolloReactHooks from "@apollo/react-hooks"

export type RebuildSitePreviewMutationVariables = Types.Exact<{
  id: Types.Scalars["UUID"]
  deleteCache?: Types.Maybe<Types.Scalars["Boolean"]>
}>

export type RebuildSitePreviewMutation = { __typename?: "Mutation" } & {
  rebuildSite?: Types.Maybe<
    { __typename?: "RebuildSiteResult" } & {
      site?: Types.Maybe<
        { __typename?: "Site" } & Pick<Types.Site, "id" | "previewStatus">
      >
      build?: Types.Maybe<
        { __typename?: "Build" } & Pick<
          Types.Build,
          | "id"
          | "siteId"
          | "branch"
          | "buildType"
          | "structuredBuild"
          | "buildStatus"
          | "createdAt"
          | "endedAt"
          | "duration"
        > & {
            commit?: Types.Maybe<
              { __typename?: "BuildCommit" } & Pick<
                Types.BuildCommit,
                "id" | "sha" | "name" | "avatarUrl" | "message"
              >
            >
          }
      >
    }
  >
}

export const RebuildSitePreviewDocument = gql`
  mutation RebuildSitePreview($id: UUID!, $deleteCache: Boolean) {
    rebuildSite(id: $id, deleteCache: $deleteCache) {
      site {
        id
        previewStatus
      }
      build {
        id
        siteId
        branch
        buildType
        structuredBuild
        buildStatus
        createdAt
        endedAt
        duration
        commit {
          id
          sha
          name
          avatarUrl
          message
        }
      }
    }
  }
`
export type RebuildSitePreviewMutationFn = ApolloReactCommon.MutationFunction<
  RebuildSitePreviewMutation,
  RebuildSitePreviewMutationVariables
>

/**
 * __useRebuildSitePreviewMutation__
 *
 * To run a mutation, you first call `useRebuildSitePreviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRebuildSitePreviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rebuildSitePreviewMutation, { data, loading, error }] = useRebuildSitePreviewMutation({
 *   variables: {
 *      id: // value for 'id'
 *      deleteCache: // value for 'deleteCache'
 *   },
 * });
 */
export function useRebuildSitePreviewMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RebuildSitePreviewMutation,
    RebuildSitePreviewMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RebuildSitePreviewMutation,
    RebuildSitePreviewMutationVariables
  >(RebuildSitePreviewDocument, baseOptions)
}
export type RebuildSitePreviewMutationHookResult = ReturnType<
  typeof useRebuildSitePreviewMutation
>
export type RebuildSitePreviewMutationResult = ApolloReactCommon.MutationResult<
  RebuildSitePreviewMutation
>
export type RebuildSitePreviewMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RebuildSitePreviewMutation,
  RebuildSitePreviewMutationVariables
>
