import { isBuildRunnerType } from "../../shared/utils"

export function fetchNextPageOnScroll(
  siteId,
  runnerType,
  pageInfo,
  fetchMore,
  limit
) {
  if (pageInfo && !pageInfo.hasNextPage) {
    return
  }

  if (pageInfo && !pageInfo.endCursor) {
    return
  }

  const buildsRunnerType = isBuildRunnerType(runnerType)
    ? `buildsForSite`
    : `previewBuildsForSite`

  fetchMore({
    variables: {
      siteId,
      pagination: {
        limit,
        endCursor: pageInfo && pageInfo.endCursor,
      },
    },
    updateQuery: (previousResult, { fetchMoreResult }) => {
      if (!fetchMoreResult) {
        return previousResult
      }

      if (
        fetchMoreResult[buildsRunnerType] &&
        fetchMoreResult[buildsRunnerType].builds &&
        fetchMoreResult[buildsRunnerType].builds.length === 0
      ) {
        return previousResult
      }

      return {
        ...previousResult,
        [buildsRunnerType]: {
          __typename: previousResult[buildsRunnerType].__typename,
          builds: [
            ...previousResult[buildsRunnerType].builds,
            ...fetchMoreResult[buildsRunnerType].builds,
          ],
          pageInfo: fetchMoreResult[buildsRunnerType].pageInfo,
        },
      }
    },
  })
}
