import React from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import { MdSettings } from "react-icons/md"
import { useLocation } from "@reach/router"
import { TabsNav } from "@modules/ui/components/TabsNav"
import { SiteDetailsTab } from "../constants.js"
import { useTracker } from "@modules/analytics"
import { getPathToSiteDetailsTab } from "../utils"

const siteTabIcons = {
  [SiteDetailsTab.Settings]: MdSettings,
}

const propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tab: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  activeTab: PropTypes.string,
  siteId: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
}

function SiteDetailsTabsNav({ tabs, siteId, organizationId }) {
  const { trackButtonClicked } = useTracker()
  const location = useLocation()

  const activeTab = tabs.find(({ tab }) => location.pathname.includes(tab))?.tab

  return (
    <TabsNav>
      <TabsNav.Tabs>
        {tabs.map(({ tab, label }, idx) => {
          const isLastTab = idx === tabs.length - 1
          const isActiveTab = tab === activeTab
          const Icon = siteTabIcons[tab]
          const TabIcon = Icon ? <Icon css={TabsNav.iconStyle} /> : null

          return (
            <TabsNav.Tab key={tab} isLast={isLastTab}>
              {isActiveTab ? (
                <TabsNav.NoLink data-cy={`site-details-${tab}-tab-no-link`}>
                  {label}
                  {TabIcon}
                </TabsNav.NoLink>
              ) : (
                <TabsNav.Link
                  data-cy={`site-details-${tab}-tab-button`}
                  to={getPathToSiteDetailsTab(tab, siteId, organizationId)}
                  onClick={() => {
                    trackButtonClicked(label, {
                      siteId,
                      uiSource: `Site Settings`,
                    })
                  }}
                >
                  {label}
                  {TabIcon}
                </TabsNav.Link>
              )}
              {isActiveTab && <Helmet title={label} />}
            </TabsNav.Tab>
          )
        })}
      </TabsNav.Tabs>
    </TabsNav>
  )
}

SiteDetailsTabsNav.propTypes = propTypes

export default SiteDetailsTabsNav
