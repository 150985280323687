import React from "react"
import { Chip } from "gatsby-interface"

import BuildStatusIndicator from "@modules/build/shared/components/BuildStatusIndicator"
import { BuildRunnerType, PreviewStatus } from "@modules/graphql/types"

const visualLabelStyles = {
  live: {
    copy: "Live",
    styles: theme => ({
      color: theme.colors.green[50],
      borderColor: theme.colors.green[10],
    }),
  },
  error: {
    copy: "Error",
    styles: theme => ({
      color: theme.colors.red[70],
      borderColor: theme.colors.red[10],
    }),
  },
  stopped: {
    copy: "Stopped",
    styles: theme => ({
      color: theme.colors.red[70],
      borderColor: theme.colors.red[10],
    }),
  },
  updating: {
    copy: "Updating",
    styles: theme => ({
      borderColor: theme.colors.purple[10],
      color: theme.colors.grey[80],
    }),
  },
}

const labelMap = {
  [PreviewStatus.Ready]: visualLabelStyles.live,
  [PreviewStatus.Pending]: visualLabelStyles.updating,
  [PreviewStatus.Building]: visualLabelStyles.updating,
  [PreviewStatus.Stopped]: visualLabelStyles.stopped,
  [PreviewStatus.Rebuilding]: visualLabelStyles.updating,
  [PreviewStatus.BuildError]: visualLabelStyles.error,
  [PreviewStatus.SystemError]: visualLabelStyles.error,
  [PreviewStatus.Unauthorized]: visualLabelStyles.error,
}

const baseStyles = theme => ({
  fontWeight: `600`,
  fontSize: theme.fontSizes[1],
  color: theme.colors.grey[70],
  backgroundColor: theme.colors.white,
  paddingLeft: theme.space[3],
})

const copyStyles = theme => ({
  marginLeft: theme.space[3],
})

function BuildTicTac({ sitePreviewStatus, siteBranch, a11yId }) {
  const { copy, styles } = labelMap[
    sitePreviewStatus || PreviewStatus.Rebuilding
  ]

  return (
    <Chip
      data-testid="build-tic-tac"
      icon={
        <BuildStatusIndicator
          a11yId={a11yId}
          buildStatus={sitePreviewStatus}
          runnerType={BuildRunnerType.Preview}
          branch={siteBranch}
        />
      }
      css={theme => [baseStyles(theme), styles(theme)]}
    >
      <span css={copyStyles}>{copy}</span>
    </Chip>
  )
}

export default BuildTicTac
