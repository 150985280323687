import * as React from "react"
import { Heading, ThemeCss } from "gatsby-interface"

const eyebrowCss: ThemeCss = theme => ({
  fontFamily: theme.fonts.body,
  fontSize: theme.fontSizes[0],
  fontWeight: theme.fontWeights.body,
  lineHeight: theme.lineHeights.default,
  color: theme.colors.grey[50],
  textTransform: `uppercase`,
})

const titleCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[6],
  color: theme.colors.black,
  flexShrink: 0,
  whiteSpace: `normal`,
  [theme.mediaQueries.tablet]: {
    whiteSpace: `nowrap`,
  },
})

export type DetailsViewHeaderProps = {
  children: React.ReactNode
  eyebrow: React.ReactNode
  className?: string
}

export function DetailsViewHeader({
  children,
  eyebrow,
  className,
}: DetailsViewHeaderProps) {
  return (
    <Heading as="h1" css={titleCss} className={className}>
      <div css={eyebrowCss}>{`${eyebrow} `} </div>
      {children}
    </Heading>
  )
}
