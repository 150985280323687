import * as React from "react"

export const useDebouncedInput = (delay = 200) => {
  const [inputValue, setInputvalue] = React.useState("")
  const [debouncedValue, setDebouncedValue] = React.useState("")
  const [isDebouncing, setIsDebouncing] = React.useState(false)

  React.useEffect(() => {
    setIsDebouncing(true)

    const timerId = setTimeout(() => {
      setDebouncedValue(inputValue)
      setIsDebouncing(false)
    }, delay)

    return () => {
      clearTimeout(timerId)
    }
  }, [inputValue])

  return [inputValue, debouncedValue, setInputvalue, isDebouncing] as const
}
