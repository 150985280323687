import * as React from "react"
import { BaseAnchor, EmptyState, Spacer, ThemeCss } from "gatsby-interface"
import { FunctionCard } from "@modules/function/components/FunctionCard"
import { FunctionInvokationStatus } from "../../site/details/components/mocks/functions.mocks"
import { useFunctionsForSiteQuery } from "../queries.generated"
import { ErrorAlert } from "@modules/ui/components/ErrorAlert"
import Loading from "@modules/ui/components/Loading"
import { functionsView as text } from "@modules/locales/default.json"
import { FormattedMessage } from "@modules/locales"

const listCss: ThemeCss = _theme => ({
  listStyle: `none`,
  margin: 0,
  padding: 0,
})

export type FunctionsListProps = {
  organizationId: string
  siteId: string
}

export function FunctionsList({ organizationId, siteId }: FunctionsListProps) {
  const { data, loading, error } = useFunctionsForSiteQuery({
    variables: {
      siteId,
    },
  })

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <ErrorAlert>{error.message}</ErrorAlert>
  }

  const functions = data?.functionsForSite ?? []

  if (functions.length === 0) {
    return (
      <EmptyState
        variant="BORDERED"
        heading={text.headers.noFunctions}
        text={
          <FormattedMessage<never, "link">
            message={text.messages.noFunctionsDescription}
            // @TODO provide a proper link to docs
            tags={{
              link: function(content) {
                return (
                  <BaseAnchor href="gatsbyjs.com/docs">{content}</BaseAnchor>
                )
              },
            }}
          />
        }
      />
    )
  }

  return (
    <ul css={listCss}>
      {functions.map(({ id, path }) => {
        return (
          <li key={id}>
            <FunctionCard
              organizationId={organizationId}
              siteId={siteId}
              id={id}
              name={path}
              // @TODO use proper value instead of mocked one
              lastInvocationStatus={FunctionInvokationStatus.Success}
              // @TODO use proper value instead of mocked one
              lastInvokedAt={new Date().toString()}
            />
            <Spacer size={5} />
          </li>
        )
      })}
    </ul>
  )
}
