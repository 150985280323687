import { usePreviewForSite } from "@modules/build/shared/hooks/usePreviewForSite"
import { useSubscription } from "react-apollo"
import { SITE_PREVIEW_UPDATED_SUBSRIPTION } from "@modules/site/cmsPreview/queries"
import { BuildStatus } from "@modules/graphql/types"

const BUILD_PAGE_LIMIT = 10

export const usePreview = siteId => {
  const [isLoading, error, previewBuildsForSite, fetchMore] = usePreviewForSite(
    siteId,
    BUILD_PAGE_LIMIT
  )

  useSubscription(SITE_PREVIEW_UPDATED_SUBSRIPTION, {
    variables: { id: siteId },
  })

  const previews = (previewBuildsForSite && previewBuildsForSite.builds) || []
  const lastPreview = previews[0]

  return {
    lastPreview,
    previews,
    initialLoading: isLoading && !previewBuildsForSite,
    error,
    isHeaderHidden:
      lastPreview?.buildStatus === BuildStatus.Queued && previews.length === 0,
    loadMore: fetchMore,
    loading: isLoading && previewBuildsForSite,
    pageInfo: previewBuildsForSite?.pageInfo || {},
  }
}
