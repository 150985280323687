/* tslint:disable:@typescript-eslint/no-non-null-assertion */
import * as React from "react"
import { WindowLocation } from "@reach/router"
import { Feed, FeedItem } from "@modules/a11y/components/Feed"
import { Spacer, ThemeCss, Heading, Text } from "gatsby-interface"
import { FormattedMessage } from "@modules/locales"
import {
  PageWithTabsContentSection,
  StandardSingleColumn,
} from "@modules/ui/layouts/Containers"
import { useLocalSiteDetailsForBuilds } from "@modules/site/shared/hooks/useLocalSiteDetails"
import { DeployCard } from "@modules/build/card/components/DeployCard"
import {
  getPathToBuildDetails,
  getPathToBuildsForBranch,
  getPathToBuildsForPR,
} from "@modules/site/details/utils"
import SiteBranch from "@modules/site/shared/components/SiteBranch"
import BuildPublishedDate from "@modules/build/shared/components/BuildPublishedDate"
import TriggerBuild from "@modules/build/shared/components/TriggerBuild"
import { useLatestBuildByStatus } from "@modules/build/shared/hooks/useLatestBuildByStatus"
import Loading from "@modules/ui/components/Loading"
import { deploysView as deploysViewText } from "@modules/locales/default.json"
import { Waypoint } from "react-waypoint"
import { usePullRequests } from "../hooks/useDeploys"
import { LastBuildInfo } from "../../buildsView/components/LastBuildInfo"
import {
  DeploysPaneLastBuild,
  DeploysPaneContent,
} from "../layouts/DeploysPaneLayouts"
import { BuildStatus, SourceControlProvider } from "@modules/graphql/types"
import { useSiteChangedSubscription } from "@modules/site/shared/queries.generated"
import { NoDeploys } from "./NoDeploys"
import { hasSourcePullRequestPermission } from "@modules/site/shared/helpers"
import { PullRequestPermission } from "./PullRequestPermission"
import Searchbar from "@modules/site/shared/components/Searchbar"
import { useDebouncedInput } from "@modules/toolkit/hooks/useDebouncedInput"
import { ExternalLink } from "@modules/ui/components/ExternalLink"
import useSiteDetailsData from "@modules/site/shared/hooks/useSiteDetailsData"
import { useLocation } from "@reach/router"
import * as qs from "query-string"
import { useFlags } from "@modules/featureFlags"
import { Bull } from "@modules/ui/components/Bull"

const headerCss: ThemeCss = theme => ({
  margin: 0,
  padding: 0,
  flexBasis: `100%`,
  fontSize: theme.fontSizes[4],
})

const subHeaderCss: ThemeCss = theme => ({
  margin: 0,
  padding: 0,
  fontSize: theme.fontSizes[1],
})

const triggerButtonBoxCss: ThemeCss = theme => ({
  textAlign: "right",
  marginLeft: theme.space[5],
  marginTop: theme.space[5],

  [theme.mediaQueries.desktop]: {
    marginTop: 0,
  },
})

export type DeploysProps = {
  organizationId: string
  siteId: string
  location?: WindowLocation
}

export function Deploys({ organizationId, siteId }: DeploysProps) {
  const { flags } = useFlags()
  const [
    searchValue,
    debouncedValue,
    setSearchValue,
    isDebouncing,
  ] = useDebouncedInput()

  const { search } = useLocation()
  const { newsite: isNewSite } = qs.parse(search)

  const siteDetails = useLocalSiteDetailsForBuilds(siteId)
  const productionBranch = siteDetails.branch
  const repositoryUrl = siteDetails.repository?.url

  const [
    deploys,
    { loading: loadingDeploys, loadingMore, loadMore },
  ] = usePullRequests(siteId, debouncedValue)

  const [
    loadingLastBuild,
    lastBuild,
    refreshLatestBuild,
  ] = useLatestBuildByStatus({
    siteId,
    siteBranch: productionBranch,
    status: BuildStatus.Success,
  })

  const lastBuildForBranch = siteDetails?.latestBuild

  useSiteChangedSubscription({
    variables: { id: siteId },
  })

  const [, { startPolling, stopPolling }] = useSiteDetailsData(siteId, {
    shouldSkip: !isNewSite,
  })

  if (isNewSite) {
    startPolling(3000)
  }

  if (siteDetails?.stableBuildURL) {
    stopPolling()
  }

  const hasPullRequestPermissions = hasSourcePullRequestPermission(
    siteDetails?.sourceOrganization?.permissions || []
  )

  const showNoDeploys =
    hasPullRequestPermissions &&
    deploys.length === 0 &&
    !searchValue &&
    !isDebouncing

  const showEmptySearchResponse =
    hasPullRequestPermissions &&
    deploys.length === 0 &&
    searchValue &&
    !isDebouncing

  return (
    <PageWithTabsContentSection>
      <StandardSingleColumn>
        <Spacer size={5} />
        {(loadingLastBuild || loadingDeploys) && (
          <PageWithTabsContentSection>
            <StandardSingleColumn>
              <Loading />
            </StandardSingleColumn>
          </PageWithTabsContentSection>
        )}
        {/* Latest successful build for the production branch */}
        <DeploysPaneLastBuild>
          <Heading
            as="h2"
            id="production-builds"
            css={headerCss}
            fontVariant="UI"
          >
            {flags.gatsbyHosting
              ? deploysViewText.headers.latestProductionBuild
              : deploysViewText.headers.productionBuildTitle}
          </Heading>

          <Spacer size={5} css={{ flexBasis: `100%` }} />

          <div css={{ flex: 1 }}>
            {!flags.gatsbyHosting && (
              <React.Fragment>
                {/** TODO: flags.ortmsv2 => remove the "else" part of the ternary when pretty URL will reach out */}
                {siteDetails?.stableBuildURL ? (
                  <ExternalLink href={siteDetails?.stableBuildURL} truncate />
                ) : (
                  <LastBuildInfo buildId={lastBuild?.id} />
                )}
              </React.Fragment>
            )}

            <Spacer size={3} />
            <div css={{ color: `red` }}>
              <SiteBranch>
                <strong>{productionBranch}</strong>
              </SiteBranch>

              <Spacer size={5} direction="horizontal" />

              {lastBuild?.endedAt && (
                <BuildPublishedDate buildPublishedDate={lastBuild.endedAt} />
              )}

              {flags.gatsbyHosting && siteDetails?.stableBuildURL && (
                <React.Fragment>
                  <Bull />
                  <Text size={"S"} as="span">
                    {deploysViewText.labels.viewLatestBuildAt}{" "}
                  </Text>
                  <ExternalLink
                    href={siteDetails?.stableBuildURL}
                    truncate
                    size={1}
                  />
                </React.Fragment>
              )}
            </div>
          </div>

          <div css={triggerButtonBoxCss}>
            <TriggerBuild
              siteId={siteId}
              selectedBranch={productionBranch}
              orgStatus={siteDetails.organization.status}
              size={`M`}
            />
          </div>
        </DeploysPaneLastBuild>
        {/* Latest build (successful or otherwise) for the production branch */}
        {lastBuildForBranch && (
          <div data-testid="latest-builds-for-production-branch">
            <Spacer size={5} />
            <DeployCard
              id={`deploy-production-branch-${lastBuildForBranch?.id}`}
              siteId={siteId}
              organizationId={organizationId}
              pullRequestId={undefined}
              title={lastBuildForBranch?.commit?.message || productionBranch}
              buildId={lastBuildForBranch?.id}
              status={lastBuildForBranch?.buildStatus}
              createdAt={lastBuildForBranch?.createdAt}
              startedAt={lastBuildForBranch?.startedAt}
              /* backend allows for null. Fallbacking to undefined for safe types in child components */
              duration={lastBuildForBranch?.duration || undefined}
              endedAt={lastBuildForBranch?.endedAt}
              branch={productionBranch}
              /* backend allows for null. Fallbacking to undefined for safe types in child components */
              commit={lastBuildForBranch?.commit || undefined}
              /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
              runnerType={lastBuildForBranch?.runnerType!}
              /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
              buildType={lastBuildForBranch?.buildType!}
              /* backend allows for null. Fallbacking to undefined for safe types in child components */
              source={lastBuildForBranch?.source || undefined}
              /* backend allows for null. Fallbacking to undefined for safe types in child components */
              author={lastBuildForBranch?.author}
              isProductionBranch={true}
              viewDetailsHref={getPathToBuildDetails(
                lastBuildForBranch?.id,
                siteId,
                organizationId
              )}
              viewAllBuildsHref={getPathToBuildsForBranch(
                productionBranch,
                siteId,
                organizationId
              )}
              deployStartedAt={lastBuildForBranch?.deployStartedAt}
              deployEndedAt={lastBuildForBranch?.deployEndedAt}
              onBuildSucceed={refreshLatestBuild}
              repositoryUrl={repositoryUrl}
            />
          </div>
        )}
        <Spacer size={9} />
        <DeploysPaneContent>
          {flags.gatsbyHosting ? (
            <Heading as="h2" id="deploys" css={headerCss} fontVariant="UI">
              {siteDetails?.repository?.provider ===
              SourceControlProvider.Gitlab
                ? deploysViewText.headers.mergeRequestBuilds
                : deploysViewText.headers.pullRequestBuilds}
            </Heading>
          ) : (
            <Heading as="h2" id="deploys" css={headerCss} fontVariant="UI">
              {deploysViewText.headers.deploysListTitle}
            </Heading>
          )}

          <Spacer size={3} />

          <Text css={subHeaderCss}>
            {flags.gatsbyHosting ? (
              <FormattedMessage<"productionBranch", "strong">
                message={
                  siteDetails?.repository?.provider ===
                  SourceControlProvider.Gitlab
                    ? deploysViewText.messages.mergeRequestBuilds
                    : deploysViewText.messages.pullRequestBuilds
                }
                values={{
                  productionBranch,
                }}
                tags={{
                  strong: function(content) {
                    return <strong>{content}</strong>
                  },
                }}
              />
            ) : (
              <FormattedMessage<"productionBranch", "strong">
                message={
                  siteDetails?.repository?.provider ===
                  SourceControlProvider.Gitlab
                    ? deploysViewText.messages.deploysListSubtitleGitlab
                    : deploysViewText.messages.deploysListSubtitleGithub
                }
                values={{
                  productionBranch,
                }}
                tags={{
                  strong: function(content) {
                    return <strong>{content}</strong>
                  },
                }}
              />
            )}
          </Text>
          <Spacer size={7} />

          <Searchbar
            ariaLabel={
              siteDetails?.repository?.provider === SourceControlProvider.Gitlab
                ? deploysViewText.labels.searchInputGitlab
                : deploysViewText.labels.searchInputGithub
            }
            placeholder={
              siteDetails?.repository?.provider === SourceControlProvider.Gitlab
                ? deploysViewText.labels.searchInputPlaceholderGitlab
                : deploysViewText.labels.searchInputPlaceholderGithub
            }
            value={searchValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchValue(e.target.value)
            }}
          />

          {showEmptySearchResponse && (
            <React.Fragment>
              <Spacer size={3} />
              <Text>{deploysViewText.messages.noSearchResults}</Text>
            </React.Fragment>
          )}

          <Spacer size={5} />

          {showNoDeploys && <NoDeploys productionBranch={productionBranch} />}
          {!hasPullRequestPermissions && (
            <PullRequestPermission
              permissionsUrl={siteDetails.sourceOrganization.permissionsURL}
            />
          )}

          <Feed
            loading={loadingDeploys || loadingMore}
            labelledBy="deploys"
            count={deploys.length}
          >
            <div>
              {deploys.map((build, index) => (
                <div key={`deploy-${build.id}`}>
                  <FeedItem
                    labelledBy={`deploy-${build.id}`}
                    describedBy={`deploy-status-${build.id}`}
                    position={index + 1}
                    id={build.id}
                  >
                    <DeployCard
                      id={`deploy-${build.id}`}
                      siteId={siteId}
                      organizationId={organizationId}
                      pullRequestId={build?.pullRequest?.id}
                      title={build.pullRequest?.title || build.branch || ``}
                      buildId={build.id}
                      status={build.buildStatus}
                      createdAt={build.createdAt}
                      startedAt={build.startedAt}
                      /* backend allows for null. Fallbacking to undefined for safe types in child components */
                      duration={build.duration || undefined}
                      endedAt={build.endedAt}
                      branch={build.branch || ``}
                      /* backend allows for null. Fallbacking to undefined for safe types in child components */
                      commit={build.commit || undefined}
                      /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
                      runnerType={build.runnerType!}
                      /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
                      buildType={build.buildType!}
                      /* backend allows for null. Fallbacking to undefined for safe types in child components */
                      source={build.source || undefined}
                      /* backend allows for null. Fallbacking to undefined for safe types in child components */
                      author={build.author || undefined}
                      isProductionBranch={productionBranch === build.branch}
                      viewDetailsHref={getPathToBuildDetails(
                        build.id,
                        siteId,
                        organizationId
                      )}
                      viewAllBuildsHref={getPathToBuildsForPR(
                        build.pullRequest?.id,
                        siteId,
                        organizationId
                      )}
                      deployStartedAt={build?.deployStartedAt}
                      deployEndedAt={build?.deployEndedAt}
                      onBuildSucceed={refreshLatestBuild}
                      repositoryUrl={repositoryUrl}
                    />
                  </FeedItem>
                  <Spacer size={5} />
                </div>
              ))}
            </div>
          </Feed>

          {loadingMore && (
            <div css={{ textAlign: "center" }}>
              <Loading variant="baby" message="Loading deploys..." />
            </div>
          )}
          <Waypoint onEnter={loadMore} />
        </DeploysPaneContent>
      </StandardSingleColumn>
    </PageWithTabsContentSection>
  )
}
