import useSiteDetailsData from "@modules/site/shared/hooks/useSiteDetailsData"
import useSetFSRole from "@modules/fullStory/hooks/useSetFSRole"
import { GatsbyVersions } from "@modules/enums/constants"
import { useGatsbyVersions } from "@modules/site/details/hooks/useGatsbyVersions"
import { useCmsIntegration } from "@modules/cms/shared/hooks/useCmsIntegration"
import { isCmsConnected } from "@modules/cms/shared/utils"

export const useSiteDetails = siteId => {
  const [siteDetails, { loading, error }] = useSiteDetailsData(siteId)
  const {
    cmsIntegrations,
    loading: loadingCms,
    error: errorCms,
  } = useCmsIntegration(siteId)

  const [
    lastVersions,
    { loading: loadingVersionsToUpgrade, error: errorVersionsToUpgrade },
  ] = useGatsbyVersions(siteId, GatsbyVersions.IncrementalBuilds)

  const orgStatus = siteDetails?.organization?.status || ""
  const orgBilling = siteDetails?.organization?.billing
  const orgPermissions = siteDetails?.organization?.permissions
  const siteDescription = siteDetails?.description || ""
  const siteAccess = siteDetails?.previewProtection
  const siteDirectoryPath = siteDetails?.previewDirectory
  const buildsEnabled = siteDetails?.buildsEnabled
  const permissions = siteDetails?.permissions
  const role = permissions?.role

  useSetFSRole(role)

  return {
    ...siteDetails,
    error: error || errorVersionsToUpgrade || errorCms,
    loading: loading || loadingCms || loadingVersionsToUpgrade,
    site: siteDetails,
    siteDescription,
    cmsIntegrations: (cmsIntegrations || []).filter(isCmsConnected),
    siteAccess,
    siteDirectoryPath,
    orgStatus,
    orgBilling,
    orgPermissions,
    buildsEnabled,
    incrementalBuildsEnabled: siteDetails?.incrementalBuildsEnabled,
    lastVersions,
    versionsForUpgrade: siteDetails?.versionsForUpgrade || [],
    repository: siteDetails?.repository,
    sourceOrganization: siteDetails?.sourceOrganization,
  }
}
