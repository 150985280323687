import React from "react"
import PropTypes from "prop-types"
import { MdArrowForward } from "react-icons/md"
import { Link } from "gatsby"

const propTypes = {
  to: PropTypes.string.isRequired,
  variant: PropTypes.oneOf([`PRIMARY`, `SECONDARY`]),
  children: PropTypes.node,
}

const ForwardLink = React.forwardRef(function ForwardLink(
  { children, ...props },
  ref
) {
  return (
    <Link
      css={theme => ({
        display: `inline-flex`,
        alignItems: `center`,
        color: theme.colors.gatsby,
        textDecoration: `none`,
        fontSize: theme.fontSizes[1],
        lineHeight: theme.lineHeights.solid,
        transition: theme.transitions.speed.default,
        padding: `${theme.space[2]} ${theme.space[4]}`,
        minHeight: theme.space[8],
        borderRadius: theme.radii[2],

        "article:hover &, &:hover, &:focus": {
          color: theme.colors.gatsby,
          background: theme.colors.purple[10],
        },
      })}
      {...props}
      ref={ref}
    >
      {children}{" "}
      <MdArrowForward css={theme => ({ marginLeft: theme.space[2] })} />
    </Link>
  )
})

ForwardLink.propTypes = propTypes

export default ForwardLink
