import * as React from "react"
import { Notification, Link, ThemeCss } from "gatsby-interface"
import { MdInfo, MdArrowForward } from "react-icons/md"

export type PullRequestPermissionBannerProps = {
  permissionsUrl: string
}

const contentCss: ThemeCss = () => ({
  display: "flex",
  flexDirection: "row",
  margin: 0,
  justifyContent: "space-between",
  flex: 1,
})

const linkIconCss: ThemeCss = theme => ({
  marginLeft: theme.space[1],
})

export function PullRequestPermissionBanner({
  permissionsUrl,
}: PullRequestPermissionBannerProps) {
  return (
    <Notification Icon={MdInfo}>
      <p css={contentCss}>
        <span>Deploy Previews are now available for pull requests!</span>

        <Link variant="SIMPLE" href={permissionsUrl}>
          Update permissions
          <MdArrowForward css={linkIconCss} />
        </Link>
      </p>
    </Notification>
  )
}
