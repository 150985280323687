import {
  MdRefresh,
  MdCheck,
  MdDoNotDisturb,
  MdPause,
  MdInfo,
} from "react-icons/md"
import { previewStatus } from "@modules/locales/default.json"

export const PREVIEW_STATUS_VARIANTS = {
  READY: {
    label: `Live`,
    badge: `green`,
    icon: MdCheck,
  },
  BUILDING: {
    label: `Building`,
    badge: `purple`,
    icon: MdRefresh,
    anim: true,
    note: `The Preview is building. Please wait.`,
  },
  REBUILDING: {
    label: `Building`,
    badge: `purple`,
    icon: MdRefresh,
    anim: true,
    note: `The Preview is building. Please wait.`,
  },
  STOPPED: {
    label: `Stopped`,
    badge: `red`,
    icon: MdPause,
    note: `The Preview has stopped. Please restart, or contact us at support@gatsbyjs.com.`,
  },
  PENDING: {
    label: `Pending`,
    badge: `yellow`,
    icon: MdRefresh,
    anim: true,
    note: `We're creating your Preview instance. This may take a few minutes.`,
  },
  BUILD_ERROR: {
    label: `Error`,
    badge: `red`,
    icon: MdDoNotDisturb,
    note: `Please try to restart, or contact us at support@gatsbyjs.com.`,
  },
  SYSTEM_ERROR: {
    label: `Error`,
    badge: `red`,
    icon: MdDoNotDisturb,
    note: `Please try to restart, or contact us at support@gatsbyjs.com.`,
  },
  UNAUTHORIZED: {
    label: `Unauthorized`,
    badge: `red`,
    icon: MdDoNotDisturb,
  },
  IDLE: {
    label: `Idle`,
    badge: `yellow`,
    icon: MdInfo,
    note: previewStatus.labels.previewStopped,
  },
}
