import * as React from "react"
import capitalizeStr from "@modules/toolkit/helpers/capitalizeStr"
import { PlatformLimitSummary } from "@modules/graphql/types"

export function getPlatformLimitOveragesMessage(
  summaries: PlatformLimitSummary[]
) {
  const overages = summaries
    .filter(item => item.overage)
    .map(item => {
      return {
        name: item.name,
        value: item.formattedOverageCount,
      }
    })

  return overages.reduce<React.ReactNode>((acc, cur, idx, arr) => {
    let connector

    if (idx === 0) {
      connector = ``
    } else if (idx === arr.length - 1) {
      connector = ` and `
    } else {
      connector = `, `
    }

    return (
      <React.Fragment>
        {acc}
        {connector}
        <b>{cur.value}</b> of its{" "}
        {capitalizeStr({
          str: cur.name.toLocaleLowerCase(),
        })}
      </React.Fragment>
    )
  }, ``)
}
