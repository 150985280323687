// THIS IS A GENERATED FILE
import * as Types from "../graphql/types"

import gql from "graphql-tag"
import * as ApolloReactCommon from "@apollo/react-common"
import * as ApolloReactHooks from "@apollo/react-hooks"

export type SitePlatformLimitSummariesQueryVariables = Types.Exact<{
  workspaceId: Types.Scalars["UUID"]
  siteId: Types.Scalars["UUID"]
}>

export type SitePlatformLimitSummariesQuery = { __typename?: "Query" } & {
  platformLimitSummaries?: Types.Maybe<
    Array<
      Types.Maybe<
        { __typename?: "PlatformLimitSummary" } & Pick<
          Types.PlatformLimitSummary,
          | "name"
          | "id"
          | "overage"
          | "limit"
          | "formattedLimit"
          | "overageCount"
          | "formattedOverageCount"
        > & {
            workspace: { __typename?: "PlatformUsage" } & Pick<
              Types.PlatformUsage,
              "count" | "formattedCount" | "percentageOfLimit"
            >
            site?: Types.Maybe<
              { __typename?: "PlatformUsage" } & Pick<
                Types.PlatformUsage,
                "count" | "formattedCount" | "percentageOfLimit"
              >
            >
          }
      >
    >
  >
}

export const SitePlatformLimitSummariesDocument = gql`
  query SitePlatformLimitSummaries($workspaceId: UUID!, $siteId: UUID!) {
    platformLimitSummaries(workspaceId: $workspaceId) {
      name
      id
      overage
      limit
      formattedLimit
      overageCount
      formattedOverageCount
      workspace {
        count
        formattedCount
        percentageOfLimit
      }
      site(id: $siteId) {
        count
        formattedCount
        percentageOfLimit
      }
    }
  }
`

/**
 * __useSitePlatformLimitSummariesQuery__
 *
 * To run a query within a React component, call `useSitePlatformLimitSummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSitePlatformLimitSummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSitePlatformLimitSummariesQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useSitePlatformLimitSummariesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SitePlatformLimitSummariesQuery,
    SitePlatformLimitSummariesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    SitePlatformLimitSummariesQuery,
    SitePlatformLimitSummariesQueryVariables
  >(SitePlatformLimitSummariesDocument, baseOptions)
}
export function useSitePlatformLimitSummariesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SitePlatformLimitSummariesQuery,
    SitePlatformLimitSummariesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    SitePlatformLimitSummariesQuery,
    SitePlatformLimitSummariesQueryVariables
  >(SitePlatformLimitSummariesDocument, baseOptions)
}
export type SitePlatformLimitSummariesQueryHookResult = ReturnType<
  typeof useSitePlatformLimitSummariesQuery
>
export type SitePlatformLimitSummariesLazyQueryHookResult = ReturnType<
  typeof useSitePlatformLimitSummariesLazyQuery
>
export type SitePlatformLimitSummariesQueryResult = ApolloReactCommon.QueryResult<
  SitePlatformLimitSummariesQuery,
  SitePlatformLimitSummariesQueryVariables
>
