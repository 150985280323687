import React from "react"
import PropTypes from "prop-types"
import gql from "graphql-tag"
import { useQuery } from "react-apollo"
import { MdWarning } from "react-icons/md"

const propTypes = {
  siteId: PropTypes.string.isRequired,
  buildId: PropTypes.string.isRequired,
}

/**
 * Leaving this here so it's closed to the code it deals with
 */
export const BUILD_WARNINGS_QUERY = gql`
  query logCountsForBuild($siteId: UUID!, $buildId: UUID!) {
    logCountsForBuild(siteId: $siteId, buildId: $buildId) {
      WARNING
    }
  }
`

export function BuildWarnings({ siteId, buildId }) {
  const { data, error, loading } = useQuery(BUILD_WARNINGS_QUERY, {
    variables: { siteId, buildId },
  })

  if (loading) {
    return null
  }

  if (error) {
    return null
  }

  if (
    !data ||
    !data.logCountsForBuild ||
    data.logCountsForBuild.WARNING === 0
  ) {
    return null
  }

  return (
    <div
      aria-label={`${data.logCountsForBuild.WARNING} warning(s)`}
      css={theme => ({
        color: theme.colors.orange[70],
        marginRight: theme.space[3],
        display: `inline-flex`,
        alignItems: `center`,
        fontSize: theme.fontSizes[1],
        [theme.mediaQueries.tablet]: {
          fontSize: theme.fontSizes[0],
        },
      })}
    >
      <MdWarning css={theme => ({ marginRight: theme.space[2] })} />
      {data.logCountsForBuild.WARNING}
    </div>
  )
}

BuildWarnings.propTypes = propTypes
