import React from "react"

// Maximum number of ms between mousedown and mouseup events
// to consider the interaction a "click"
const CARD_CLICK_THRESHOLD = 200

/**
 * This hook exposes a way to make a card with and interactive element clickable
 *
 * It is inspired by the Cards article from Inclusive Components
 * https://inclusive-components.design/cards
 */
export default function useCardClickHandlers() {
  const clickableElementRef = React.useRef()
  const mouseDownTime = React.useRef(0)
  const [eventButtons, setEventButtons] = React.useState()

  const onMouseDown = e => {
    // save information about which mouse button was pressed
    setEventButtons(e.buttons)

    mouseDownTime.current = new Date().getTime()
  }

  const onMouseUp = e => {
    // if different than primary (left) button was pressed we do nothing
    if (eventButtons !== 1) {
      return
    }

    if (!clickableElementRef.current) {
      return
    }

    if (e.target === clickableElementRef.current) {
      return
    }

    const mouseUpTime = new Date().getTime()
    // Setting up a threshold allows us to support selecting text inside the card
    // more details here: https://inclusive-components.design/cards/#theredundantclickevent
    if (mouseUpTime - mouseDownTime.current < CARD_CLICK_THRESHOLD) {
      if (e.shiftKey) {
        return window.open(clickableElementRef.current.href)
      }
      if (e.metaKey || e.ctrlKey || e.altKey) {
        return window.open(clickableElementRef.current.href, `_blank`)
      }
      clickableElementRef.current.click()
    }
  }

  // We're not adding a tabIndex in "cardProps" since clicking on a card is just
  // an additional feature for mouse/touchscreen users and relies on the link to be present
  // therefore we don't need to add a redundant tab stop
  return {
    clickableElementRef,
    cardProps: {
      onMouseDown,
      onMouseUp,
    },
  }
}
