import * as React from "react"
import { SplitButton, DropdownMenuItem } from "gatsby-interface"
import { useTracker } from "@modules/analytics"
import { useGenericError } from "@modules/modal"
import { SitePermissions } from "@modules/site/permissions"
import { useRebuildSitePreviewMutation } from "@modules/site/cmsPreview/queries.generated"
import { cmsPreview as cmsPreviewText } from "@modules/locales/default.json"

export type RestartPreviewButtonProps = {
  organizationId: string
  siteId: string
  disabled?: boolean
}

export const RestartPreviewButton = React.forwardRef<
  HTMLButtonElement,
  RestartPreviewButtonProps
>(function RestartPreviewButton(
  { siteId, organizationId, disabled },
  forwardedRef
) {
  const { trackButtonClicked } = useTracker()
  const [rebuildSiteMutation, { loading }] = useRebuildSitePreviewMutation()
  const throwError = useGenericError()

  const isDisabled = disabled || loading

  const restartPreview = (deleteCache = false) => {
    if (isDisabled) {
      return
    }
    trackButtonClicked(`Rebuild Site`, {
      organizationId,
      uiSource: `Site previews`,
      siteId,
    })

    rebuildSiteMutation({ variables: { id: siteId, deleteCache } }).catch(
      err => {
        throwError({ message: err.message })
      }
    )
  }

  return (
    <SitePermissions id={siteId} resource="sites" action="edit">
      <div
        ref={elem => {
          // This is a hack around SplitButton not currently supporting ref
          const button = elem?.querySelector(
            `button`
          ) as HTMLButtonElement | null
          if (typeof forwardedRef === `function`) {
            forwardedRef(button)
          } else if (forwardedRef) {
            forwardedRef.current = button
          }
        }}
      >
        <SplitButton
          buttonLabel={cmsPreviewText.actions.restart}
          dropdownButtonLabel={cmsPreviewText.actions.viewMoreActions}
          onClick={() => restartPreview(false)}
          size={`M`}
          disabled={isDisabled}
        >
          <DropdownMenuItem onSelect={() => restartPreview(true)}>
            {cmsPreviewText.actions.clearCacheAndRestart}
          </DropdownMenuItem>
        </SplitButton>
      </div>
    </SitePermissions>
  )
})
