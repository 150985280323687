import * as React from "react"
import { debounce } from "../utils/debounce"
import { ThemeCss } from "gatsby-interface"

const connectorCss: ThemeCss = theme => ({
  position: `absolute`,
  width: `1px`,
  height: `auto`,
  backgroundColor: theme.colors.blackFade[10],
  zIndex: 0,
})

export type GetConnectorAnchorOffset = (
  element: HTMLElement
) => { top: number; left: number }

export type TimelineProps<
  HomeFocusEl extends HTMLElement,
  EndFocusEl extends HTMLElement
> = React.ComponentPropsWithoutRef<"div"> & {
  getConnectorAnchorOffset: GetConnectorAnchorOffset
  itemsLoaded: boolean
  homeFocusTarget: HomeFocusEl | null
  endFocusTarget: EndFocusEl | null
}

export default function Timeline<
  HomeFocusEl extends HTMLElement,
  EndFocusEl extends HTMLElement
>({
  getConnectorAnchorOffset,
  itemsLoaded,
  homeFocusTarget,
  endFocusTarget,
  children,
  ...rest
}: TimelineProps<HomeFocusEl, EndFocusEl>) {
  const listRef = React.useRef<HTMLDivElement>(null)
  const connectorRef = React.useRef<HTMLSpanElement>(null)

  React.useEffect(() => {
    if (!listRef.current || !connectorRef.current) {
      return
    }

    const updatePosition = () => {
      if (!listRef.current || !connectorRef.current) {
        return
      }

      updateConnectorPosition(
        listRef.current,
        connectorRef.current,
        getConnectorAnchorOffset
      )
    }
    updatePosition()

    const debouncedUpdatePosition = debounce(updatePosition, 400)

    window.addEventListener(`resize`, debouncedUpdatePosition)

    return () => {
      window.removeEventListener(`resize`, debouncedUpdatePosition)
    }
  }, [itemsLoaded, getConnectorAnchorOffset])

  const a11yProps = useFeedA11yProps({ homeFocusTarget, endFocusTarget })

  return (
    <div css={{ position: "relative" }}>
      <div {...rest} {...a11yProps} ref={listRef}>
        {children}
      </div>
      <span ref={connectorRef} css={connectorCss} aria-hidden />
    </div>
  )
}

export type TimelineItemProps = {
  children?: React.ReactNode
}

function TimelineItem({ children }: TimelineItemProps) {
  return <React.Fragment>{children}</React.Fragment>
}

Timeline.Item = TimelineItem

function updateConnectorPosition<
  TimelineEl extends HTMLElement,
  ConnectorEl extends HTMLElement
>(
  timelineElement: TimelineEl,
  connectorElement: ConnectorEl,
  getConnectorAnchorOffset: GetConnectorAnchorOffset
) {
  const listItems = timelineElement.childNodes

  if (listItems.length < 2) {
    // No need for connecting lines if there's only 1 or less cards
    return
  }

  const firstItem = listItems[0] as HTMLElement
  const lastItem = listItems[listItems.length - 1] as HTMLElement
  const connectorOffset = getConnectorAnchorOffset(firstItem)

  // We need to adjust connector position by firstItem.offsetLeft and firstItem.offsetTop
  // to account for possible paddings in timelineElement
  connectorElement.style.left = `${connectorOffset.left +
    firstItem.offsetLeft}px`
  connectorElement.style.top = `${connectorOffset.top + firstItem.offsetTop}px`
  connectorElement.style.bottom = `${lastItem.getBoundingClientRect().height -
    connectorOffset.top +
    firstItem.offsetTop}px`

  connectorElement.style.transform = "translateX(-0.5px)"
}

function useFeedA11yProps<
  FeedEl extends HTMLElement,
  HomeFocusEl extends HTMLElement,
  EndFocusEl extends HTMLElement
>({
  homeFocusTarget,
  endFocusTarget,
}: {
  homeFocusTarget: HomeFocusEl | null
  endFocusTarget: EndFocusEl | null
}) {
  const onKeyDown: React.KeyboardEventHandler<FeedEl> = e => {
    const { key, target } = e
    // Pressing PageUp/PageDown should focus prev/next item in the feed
    if (key === `PageUp` || key === `PageDown`) {
      e.preventDefault()
      const currentItem = (target as HTMLElement).closest("[role=article]")
      if (!currentItem) {
        return
      }
      if (key === `PageUp` && currentItem.previousElementSibling) {
        ;(currentItem.previousElementSibling as HTMLElement).focus()
      } else if (key === `PageDown` && currentItem.nextElementSibling) {
        ;(currentItem.nextElementSibling as HTMLElement).focus()
      }
    }
    // Pressing Control + Home/End should focus an item before/after the feed
    if (e.ctrlKey && (key === `Home` || key === `End`)) {
      e.preventDefault()
      if (key === `Home` && homeFocusTarget) {
        homeFocusTarget.focus()
      } else if (key === `End` && endFocusTarget) {
        endFocusTarget.focus()
      }
    }
  }

  return {
    role: `feed`,
    onKeyDown,
  }
}
