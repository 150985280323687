import * as React from "react"
import { ThemeCss } from "gatsby-interface"
import { StandardSingleColumn } from "@modules/ui/layouts/Containers"

const containerCss: ThemeCss = theme => ({
  display: `grid`,
  gridRowGap: theme.space[5],
  paddingTop: theme.space[2],
  paddingBottom: theme.space[5],
})

type CommonProps = {
  children?: React.ReactNode
}

export function SiteInformationViewLayout({ children }: CommonProps) {
  return <div>{children}</div>
}

export function SiteInformationViewHeader({ children }: CommonProps) {
  return <StandardSingleColumn>{children}</StandardSingleColumn>
}

export function SiteInformationViewBanners({ children }: CommonProps) {
  return <section css={containerCss}>{children}</section>
}

const gridCss: ThemeCss = theme => ({
  display: `grid`,
  gridGap: theme.space[3],
  gridTemplateAreas: `
    "siteName"
    "description"
    "details"
  `,
  [theme.mediaQueries.tablet]: {
    gridTemplateAreas: `
      "siteName    details"
      "description details"
    `,
  },
  [theme.mediaQueries.desktop]: {
    gridTemplateAreas: `
      "siteName     details     details"
      "description  description ......."
    `,
    gridTemplateColumns: `min-content auto auto`,
  },
})

export function SiteInformationViewGrid(props: CommonProps) {
  return <div css={gridCss} {...props} />
}

const siteNameAreaCss: ThemeCss = _theme => ({ gridArea: "siteName" })

export function SiteInformationViewSiteNameArea(props: CommonProps) {
  return <div css={siteNameAreaCss} {...props} />
}

const descriptionAreaCss: ThemeCss = _theme => ({ gridArea: "description" })

export function SiteInformationViewDescriptionArea(props: CommonProps) {
  return <div css={descriptionAreaCss} {...props} />
}

const detailsAreaCss: ThemeCss = theme => ({
  gridArea: `details`,
  alignSelf: `end`,
  display: `grid`,
  gridGap: theme.space[7],
  alignItems: `center`,
  [theme.mediaQueries.tablet]: {
    gridTemplateColumns: `max-content`,
    justifyContent: `end`,
    justifyItems: `end`,
  },
  [theme.mediaQueries.desktop]: {
    gridTemplateColumns: `auto max-content`,
    justifyContent: `normal`,
    justifyItems: `normal`,
  },
})

export function SiteInformationViewDetailsArea(props: CommonProps) {
  return <div css={detailsAreaCss} {...props} />
}
