import { useSiteDependencies } from "./useSiteDependencies"
import semverSatisfies from "semver/functions/satisfies"

const extractSemverCharacters = dependency => {
  if (
    dependency.installedVersion[0] === "^" ||
    dependency.installedVersion[0] === "~"
  ) {
    dependency.installedVersion = dependency.installedVersion.substr(1)
  }

  return dependency
}

export const useGatsbyVersions = (siteId, dependencies = {}) => {
  const [dependenciesForSite, { loading, error }] = useSiteDependencies(siteId)

  const hasDeps = dependenciesForSite && dependenciesForSite.length > 0

  if (error || loading || !hasDeps) {
    return [null, { loading, error }]
  }

  const notSatisfyVersion = dependency =>
    !semverSatisfies(dependency.installedVersion, dependencies[dependency.name])

  const format = dependency =>
    `${dependency.name}@${dependencies[dependency.name].replace(">=", "^")}`

  const matchingDependencyName = dependency => dependencies[dependency.name]

  const toUpdate = dependenciesForSite
    .filter(matchingDependencyName)
    .map(extractSemverCharacters)
    .filter(notSatisfyVersion)
    .map(format)

  return [toUpdate, { loading, error }]
}
