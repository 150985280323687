import { useApolloFragment } from "apollo-fragment-react"
import { SiteDetailsFieldsFragmentDoc } from "@modules/site/shared/fragments.generated"

export const useLocalSiteDetailsForBuilds = siteId => {
  const { data } = useApolloFragment(SiteDetailsFieldsFragmentDoc, siteId)

  return data
}

const cmsPreviewDataFragment = `
  fragment SiteDetailsPreviewsFragment on Site {
    name
    branch
    previewBuildsEnabled
    previewDirectory
    previewStatus
    previewUrl
    organization {
      name
      status
    }
    repository {
      url
    }
  }
`

export const useLocalSiteDetailsForPreviews = siteId => {
  const { data } = useApolloFragment(cmsPreviewDataFragment, siteId)

  return data
}
