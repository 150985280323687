import { useSiteDependencies } from "@modules/site/details/hooks/useSiteDependencies"

export const useGatsbyHostingPluginInstalled = siteId => {
  const [dependencies, { loading }] = useSiteDependencies(siteId)

  let gatsbyPluginGatsbyCloudInstalled = false
  if (loading === false) {
    const dependency = dependencies.find(
      dependency => dependency.name === "gatsby-plugin-gatsby-cloud"
    )
    if (dependency) {
      gatsbyPluginGatsbyCloudInstalled = true
    }
  }

  return {
    gatsbyPluginGatsbyCloudInstalled,
    loading,
  }
}
