import * as React from "react"
import { Notification, Link } from "gatsby-interface"
import { MdInfo, MdArrowForward } from "react-icons/md"
import { CdnVendor, HostingDetails } from "@modules/graphql/types"
import { useGatsbyHostingNotifications } from "../hooks/useGatsbyHostingNotifications"
import { siteDetails as text } from "@modules/locales/default.json"
import { SiteDetailsTab } from "@modules/site/details/constants"
import { getSiteDetailsTabBasePath } from "@modules/site/details/utils"
import { useGatsbyHostingPluginInstalled } from "../hooks/useGatsbyHostingPluginInstalled"
import { FormattedMessage } from "@modules/locales"

type ActiveAnnouncement =
  | "noGatsbyHosting"
  | "noCustomDomain"
  | "noGatsbyCloudPlugin"
  | null

type Announcement = {
  message: React.ReactNode | string
  anchorText: string
}

const announcements: Record<string, Announcement> = {
  noGatsbyHosting: {
    message: text.messages.quicklyBoostYourWebPerf,
    anchorText: text.actions.getStartedNow,
  },
  noCustomDomain: {
    message: text.messages.yourSiteIsHostedOnGatsbyCloud,
    anchorText: text.actions.setUpCustomDomain,
  },
  noGatsbyCloudPlugin: {
    message: (
      <div>
        <FormattedMessage<"value", "strong">
          message={text.messages.pleaseInstallGatsbyCloudPlugin}
          values={{
            value: "gatsby-plugin-gatsby-cloud",
          }}
          tags={{
            strong: function(content) {
              return (
                <strong>
                  {` `}
                  {content}
                  {` `}
                </strong>
              )
            },
          }}
        />
      </div>
    ),
    anchorText: text.actions.installationInstructions,
  },
}

const announcmentLink = ({
  activeAnnouncement,
  linkToSiteSettings,
}: {
  activeAnnouncement: ActiveAnnouncement
  linkToSiteSettings: string
}) => {
  switch (activeAnnouncement) {
    case `noGatsbyCloudPlugin`:
      return `https://www.gatsbyjs.com/plugins/gatsby-plugin-gatsby-cloud/`
    default:
      return `${linkToSiteSettings}/hosting`
  }
}

export type HostingBannerProps = {
  siteId: string
  organizationId: string
  cdnVendor?: CdnVendor
  hostingDetails?: HostingDetails
}

export function HostingBanner({
  siteId,
  organizationId,
  cdnVendor,
  hostingDetails,
}: HostingBannerProps) {
  const linkToSiteSettings = getSiteDetailsTabBasePath(
    SiteDetailsTab.Settings,
    siteId,
    organizationId
  )
  const {
    hostingNotificationVisible,
    customDomainNotificationVisible,
    gatsbyCloudPluginNotificationVisible,
    hideHostingNotification,
    hideCustomDomainNotification,
    hideGatsbyCloudPluginNotification,
  } = useGatsbyHostingNotifications(siteId)

  const {
    gatsbyPluginGatsbyCloudInstalled,
    loading: pluginGatsbyCloudLoading,
  } = useGatsbyHostingPluginInstalled(siteId)

  const [activeAnnouncement, setActiveAnnouncement] = React.useState<
    ActiveAnnouncement
  >(null)

  const [onDismiss, setOnDismiss] = React.useState<() => () => void>()

  React.useEffect(() => {
    if (cdnVendor !== CdnVendor.CloudCdn && hostingNotificationVisible) {
      setActiveAnnouncement(`noGatsbyHosting`)
      setOnDismiss(() => hideHostingNotification)
    } else if (
      customDomainNotificationVisible &&
      cdnVendor === CdnVendor.CloudCdn &&
      hostingDetails?.domains &&
      hostingDetails.domains.length === 1
    ) {
      setActiveAnnouncement(`noCustomDomain`)
      setOnDismiss(() => hideCustomDomainNotification)
    } else if (
      cdnVendor === CdnVendor.CloudCdn &&
      pluginGatsbyCloudLoading === false &&
      gatsbyPluginGatsbyCloudInstalled === false &&
      gatsbyCloudPluginNotificationVisible
    ) {
      setActiveAnnouncement(`noGatsbyCloudPlugin`)
      setOnDismiss(() => hideGatsbyCloudPluginNotification)
    } else {
      setActiveAnnouncement(null)
    }
  }, [
    hostingNotificationVisible,
    customDomainNotificationVisible,
    gatsbyCloudPluginNotificationVisible,
    cdnVendor,
    hostingDetails,
  ])

  if (!activeAnnouncement) {
    return null
  }

  return (
    <React.Fragment>
      <Notification
        Icon={MdInfo}
        variant="SECONDARY"
        showDismissButton={true}
        onDismissButtonClick={onDismiss}
        isOpened={!!activeAnnouncement}
        content={
          <React.Fragment>
            {announcements[activeAnnouncement].message} &nbsp;{" "}
            <Link
              variant="SIMPLE"
              to={announcmentLink({ activeAnnouncement, linkToSiteSettings })}
            >
              {announcements[activeAnnouncement].anchorText} <MdArrowForward />{" "}
            </Link>
          </React.Fragment>
        }
      />
    </React.Fragment>
  )
}
