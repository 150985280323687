import React from "react"
import { IntegrationChip } from "@modules/site/shared/components/IntegrationChip"
import { CmsVendorLogoIcon } from "@modules/cms/shared/constants"

const chipCss = theme => ({
  marginRight: theme.space[3],
})

export function CmsChip({ vendor, url, formattedName }) {
  const IconComponent = CmsVendorLogoIcon[vendor] || null

  return (
    <IntegrationChip
      css={chipCss}
      href={url}
      label={formattedName}
      icon={IconComponent && <IconComponent />}
    />
  )
}
