import { useQuery } from "react-apollo"
import { ApolloStatus } from "@modules/enums/constants"
import { BuildRunnerType } from "@modules/graphql/types"
import { useBuildCreatedSubscription } from "@modules/build/shared/queries.generated"
import { PREVIEW_BUILDS_QUERY } from "@modules/build/shared/queries"

export const usePreviewForSite = (siteId, limit = 10) => {
  const { loading, error, refetch, fetchMore, data, networkStatus } = useQuery(
    PREVIEW_BUILDS_QUERY,
    {
      variables: {
        siteId,
        pagination: {
          limit,
        },
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: `cache-and-network`,
    }
  )

  useBuildCreatedSubscription({
    variables: {
      id: siteId,
      runnerType: BuildRunnerType.Preview,
    },
    onSubscriptionData: () => {
      refetch({
        siteId,
        pagination: {
          limit: 10,
        },
      })
    },
  })

  const isWaiting =
    loading &&
    networkStatus !== ApolloStatus.Refetching &&
    networkStatus !== ApolloStatus.Polling

  const dataLoaded = data && data.previewBuildsForSite

  return [isWaiting, error, dataLoaded, fetchMore]
}
