import React from "react"
import PropTypes from "prop-types"
import useLighthouseScoresForBuild from "@modules/build/metrics/hooks/useLighthouseScoresForBuild"
import DonutChart from "@modules/metrics/components/DonutChart"
import {
  metricsDividerCss,
  metricsLabelCellCss,
  metricsCellCss,
} from "./BuildCard.styles"

const scoreCss = theme => ({
  marginRight: theme.space[4],
  [theme.mediaQueries.tablet]: {
    marginRight: theme.space[7],
  },
})
const scoreLabelCss = theme => ({
  display: `none`,
  [theme.mediaQueries.tablet]: {
    display: `initial`,
  },
})

const propTypes = {
  buildId: PropTypes.string.isRequired,
  siteId: PropTypes.string.isRequired,
  branch: PropTypes.string,
}

/**
 * This component is tightly coupled with BuildCard's grid layout
 * and should not be used outside of that component
 */
export default function BuildCardMetricsRow({ siteId, buildId, branch }) {
  const [lighthouseScores, { loading }] = useLighthouseScoresForBuild(
    siteId,
    buildId,
    branch
  )

  if (loading || lighthouseScores.length === 0) {
    return null
  }

  return (
    <React.Fragment>
      <hr css={metricsDividerCss} />
      <div css={metricsLabelCellCss} aria-label="Lighthouse metrics">
        Lighthouse
      </div>
      <div css={metricsCellCss} data-cy="build-card-metrics">
        {lighthouseScores.map(([label, value]) => {
          return (
            <span key={label} css={scoreCss}>
              <span css={scoreLabelCss}>{label}&nbsp;</span>
              <DonutChart
                value={value}
                size={16}
                strokewidth={4}
                css={{ verticalAlign: `text-top` }}
              />
              &nbsp;<b>{value}</b>
            </span>
          )
        })}
      </div>
    </React.Fragment>
  )
}

BuildCardMetricsRow.propTypes = propTypes
