import * as React from "react"
import { ThemeCss } from "gatsby-interface"

const deploysPaneLastBuildCss: ThemeCss = theme => ({
  [theme.mediaQueries.phablet]: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
})

export function DeploysPaneLastBuild({
  children,
}: {
  children?: React.ReactNode
}) {
  return <section css={deploysPaneLastBuildCss}>{children}</section>
}

export function DeploysPaneContent({
  children,
}: {
  children?: React.ReactNode
}) {
  return <section>{children}</section>
}
