import React from "react"

export default function useHoveredState() {
  const [hovered, setHovered] = React.useState(false)

  const onMouseEnter = () => setHovered(true)
  const onMouseLeave = () => setHovered(false)

  return {
    hovered,
    setHovered,
    handlers: {
      onMouseEnter,
      onMouseLeave,
    },
  }
}
