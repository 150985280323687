import { useApolloFragment } from "apollo-fragment-react"

const orgNameFragment = `
    fragment OrgNameFragment on Organization {
        name
    }
`

export default function useOrganizationName(organizationId) {
  const { data } = useApolloFragment(orgNameFragment, organizationId)

  return data && data.name
}
