export const getCardBaseStyles = theme => [
  {
    background: theme.colors.white,
    borderRadius: theme.radii[2],
    boxShadow: theme.shadows.raised,
    overflow: `hidden`,
  },
  getCardPadding(theme),
]

export const getCardPadding = theme => ({
  paddingTop: theme.space[6],
  paddingBottom: theme.space[5],
  paddingLeft: theme.space[7],
  paddingRight: theme.space[3],
})
