import * as React from "react"
import { Link, Text, ThemeCss } from "gatsby-interface"
import { getPathToSiteSettingsSection } from "@modules/site/details/utils"
import { SiteSettingsSection } from "@modules/site/settings/constants"
import { siteDetails as siteDetailsText } from "@modules/locales/default.json"
import { SitePermissions } from "@modules/site/permissions"

const textStyles: ThemeCss = theme => ({
  color: theme.colors.grey[70],
  margin: `0`,
  maxWidth: `100%`,
  wordBreak: `break-word`,
})

export type SiteDescriptionProps = {
  siteId: string
  organizationId: string
  children?: React.ReactNode
}

export function SiteDescription({
  children,
  siteId,
  organizationId,
}: SiteDescriptionProps) {
  if (children) {
    return (
      <Text
        css={textStyles}
        size="S"
        data-cy="site-description"
        data-testid="information-view-description"
      >
        {children}
      </Text>
    )
  }

  const link = getPathToSiteSettingsSection(
    SiteSettingsSection.SiteDetails,
    siteId,
    organizationId
  )

  const linkCss: ThemeCss = theme => [
    textStyles(theme),
    { fontStyle: `italic`, fontSize: theme.fontSizes[1] },
  ]

  return (
    <SitePermissions id={siteId} resource="sites" action="edit">
      <Link variant="SIMPLE" to={link} css={linkCss}>
        {siteDetailsText.actions.addSiteDescription}
      </Link>
    </SitePermissions>
  )
}
