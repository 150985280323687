import * as React from "react"
import { BaseAnchor, Chip } from "gatsby-interface"
import { MdOpenInNew } from "react-icons/md"

export type IntegrationChipProps = {
  label: React.ReactNode
  "aria-label"?: string
  href?: string
  icon?: React.ReactNode
  className?: string
  "data-testid"?: string
}

export function IntegrationChip({
  label,
  "aria-label": ariaLabel,
  href,
  icon = null,
  className,
  "data-testid": dataTestId,
}: IntegrationChipProps) {
  const [highlighted, setHighlighted] = React.useState(false)

  const chip = (
    <Chip
      css={theme => [
        highlighted && {
          borderColor: theme.colors.blackFade["10"],
        },
      ]}
      icon={highlighted ? <MdOpenInNew /> : icon}
    >
      {label}
    </Chip>
  )

  const commonProps = {
    "aria-label": ariaLabel,
    className,
    "data-testid": dataTestId,
  }

  if (!href) {
    return <span {...commonProps}>{chip}</span>
  }

  return (
    <BaseAnchor
      href={href}
      target="_blank"
      onFocus={() => setHighlighted(true)}
      onBlur={() => setHighlighted(false)}
      onMouseEnter={() => setHighlighted(true)}
      onMouseLeave={() => setHighlighted(false)}
      css={{ textDecoration: "none" }}
      {...commonProps}
    >
      {chip}
    </BaseAnchor>
  )
}
