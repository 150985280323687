import * as React from "react"
import { siteDetails as text } from "@modules/locales/default.json"
import { visuallyHiddenCss } from "@modules/a11y/stylesheets"
import { FunctionsList } from "@modules/function/components/FunctionsList"
import {
  PageWithTabsContentSection,
  StandardSingleColumn,
} from "@modules/ui/layouts/Containers"

export type FunctionsPaneProps = {
  organizationId: string
  siteId: string
}

export function FunctionsPane({ organizationId, siteId }: FunctionsPaneProps) {
  return (
    <PageWithTabsContentSection>
      <StandardSingleColumn>
        <h2 css={visuallyHiddenCss}>{text.headers.functions}</h2>
        <FunctionsList organizationId={organizationId} siteId={siteId} />
      </StandardSingleColumn>
    </PageWithTabsContentSection>
  )
}
