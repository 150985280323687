import React from "react"
import PropTypes from "prop-types"
import { MdSearch } from "react-icons/md"
import { StyledInput } from "gatsby-interface"

const filterInputCss = theme => ({
  width: `100%`,
  padding: `0 ${theme.space[4]} 0 ${theme.space[8]}`,
  flexGrow: 1,
  zIndex: 0,
})

const searchIconCss = theme => ({
  position: `absolute`,
  color: theme.colors.grey[40],
  fontSize: theme.fontSizes[3],
  top: `calc((100% - ${theme.fontSizes[3]}) / 2)`,
  margin: `0 ${theme.space[3]}`,
  zIndex: 2,
})

function Searchbar({
  ariaLabel = `Search bar`,
  placeholder = `Enter search term`,
  value,
  onChange,
  onBlur,
  className,
}) {
  return (
    <div css={{ position: `relative`, width: `100%` }}>
      <MdSearch css={searchIconCss} aria-hidden />
      <StyledInput
        css={filterInputCss}
        aria-label={ariaLabel}
        placeholder={placeholder}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        className={className}
      />
    </div>
  )
}

Searchbar.propTypes = {
  ariaLabel: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  className: PropTypes.string,
}

export default Searchbar
