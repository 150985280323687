import gql from "graphql-tag"

export const SITE_PREVIEW_UPDATED_SUBSRIPTION = gql`
  subscription siteChanged($id: UUID!) {
    siteChanged(id: $id) {
      id
      previewStatus
      updatedAt
    }
  }
`
