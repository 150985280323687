import React, { Fragment } from "react"
import PropTypes from "prop-types"

import { Notification } from "gatsby-interface"
import { OrganizationStatus as OrganizationStatusConstants } from "@modules/graphql/types"
import { getPathToOrgSettings } from "@modules/organization/shared/utils"
import { organizationStatus as text } from "@modules/locales/default.json"

function OrganizationStatusNotification({ organizationId, status }) {
  const orgIsIdle = status === OrganizationStatusConstants.Idle
  const orgInOverage = status === OrganizationStatusConstants.Overage
  const pathToSettings = getPathToOrgSettings(organizationId)

  return (
    <Fragment>
      {orgIsIdle && (
        <Notification
          data-testid="organization-status-idle"
          tone={`WARNING`}
          content={text.messages.orgIsIdle}
          linkUrl={`${pathToSettings}/general#status`}
          linkText={text.actions.reactivate}
        />
      )}
      {orgInOverage && (
        <Notification
          data-testid="organization-status-overage"
          tone={`WARNING`}
          content={text.messages.orgInOverage}
          linkUrl={`${pathToSettings}/general#status`}
          linkText={text.actions.upgrade}
        />
      )}
    </Fragment>
  )
}

OrganizationStatusNotification.propTypes = {
  organizationId: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
}

export default OrganizationStatusNotification
