import React from "react"

const headingCss = theme => ({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  marginBottom: theme.space[10],

  [theme.mediaQueries.tablet]: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
})

const headerCss = () => ({
  flex: 1,
})

const titleCss = theme => ({
  marginBottom: theme.space[3],
})

const infoCss = theme => ({
  marginRight: theme.space[5],
})

const actionsCss = theme => ({
  marginTop: theme.space[5],
  marginLeft: "auto",

  [theme.mediaQueries.tablet]: {
    margin: 0,
  },
})

export function BuildViewAnnouncement({ children }) {
  return <div>{children}</div>
}

export function BuildViewInfo({ children }) {
  return <span css={infoCss}>{children}</span>
}

export function BuildViewTitle({ children }) {
  return <div css={titleCss}>{children}</div>
}
export function BuildViewHeading({ children }) {
  return <div css={headingCss}>{children}</div>
}
export function BuildViewActions({ children }) {
  return <div css={actionsCss}>{children}</div>
}
export function BuildsViewHeader({ children }) {
  return <header css={headerCss}>{children}</header>
}
export function BuildsViewLayout({ children }) {
  return <div>{children}</div>
}
